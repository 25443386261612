import { Prop, Component } from 'vue-property-decorator';
import DoughnutChart from '@/components/chart/DoughnutChart';

@Component
export default class CmrChart extends DoughnutChart {
  @Prop({ type: Array }) dataset!: Array<Number>;
  @Prop({ type: String, default: 'pie-cmr' }) chartId!: string;

  get chartData(): any {
    return {
      labels: [
        'CMR suspecté',
        'CMR avéré',
        'Non CMR',
      ],
      datasets: [
        {
          backgroundColor: [
            '#FCFF49',
            '#D84A38',
            '#35AA47',
          ],
          data: this.dataset,
          borderWidth: 0,
        },
      ],
    };
  }
}
