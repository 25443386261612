import { Prop, Component } from 'vue-property-decorator';
import DoughnutChart from '@/components/chart/DoughnutChart';

@Component
export default class SensibilityChart extends DoughnutChart {
  @Prop({ type: Array }) dataset!: Array<Number>;
  @Prop({ type: String, default: 'pie-sensibility' }) chartId!: string;

  get chartData(): any {
    return {
      labels: [
        'Sensibilisant Cutané',
        'Sensibilisant Respiratoire',
        'Non sensibilisant',
        'Sensibilisant Cutané et Repiratoire',
      ],
      datasets: [
        {
          backgroundColor: [
            '#FFAB81',
            '#83C5FF',
            '#aaa',
            '#AF1313',
          ],
          data: this.dataset,
          borderWidth: 0,
        },
      ],
    };
  }
}
