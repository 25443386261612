


































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Folder } from '@/types/Folder';
import { Getter } from 'vuex-class';

import Firm from '@models/firm/Firm';

@Component
export default class FolderHistory extends Vue {
  @Getter('currentFirm')
  currentFirm !: Firm;

  @Prop()
  folders!: Folder[];

  getHTML(folder: Folder) {
    const file = folder.files.find(f => f.type === 'HTML') || null;

    return file ? file.file : null;
  }

  getPDF(folder: Folder) {
    const file = folder.files.find(f => f.type === 'PDF') || null;

    return file ? file.file : null;
  }
}
