



































import { Component, Prop, Vue } from 'vue-property-decorator';
import CmrChart from '@/components/chart/CmrChart';
import SensibilityChart from '@/components/chart/SensibilityChart';

@Component({
  components: {
    SensibilityChart,
    CmrChart,
  },
})
export default class FolderCard extends Vue {
  @Prop({ type: String }) title!: string;
  @Prop({ type: Boolean, default: false }) showAction!: boolean;
}
