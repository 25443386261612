import { Doughnut } from 'vue-chartjs';
import {
  Prop, Component, Vue, Watch,
} from 'vue-property-decorator';
import { ChartTooltipModel } from 'chart.js';

@Component({
  extends: Doughnut, // this is important to add the functionality to your component
})
export default class DoughnutChart extends Vue {
  @Prop({ type: String, default: 'pie-chart' }) cssClasses!: string;
  @Prop({ type: String, default: '' }) chartId!: string;

  get chartData(): any {
    return {};
  }

  public renderChart!: (chartData: any, options?: any) => void;

  @Watch('chartData', { deep: true })
  onChartDataChange() {
    this.refreshChart();
  }

  mounted() {
    this.refreshChart();
  }

  refreshChart() {
    const tooltipCanvas: HTMLElement = document.getElementById(this.chartId) as HTMLElement;

    this.renderChart(this.chartData, {
      legend: { display: false },
      borderWidth: 0,
      cutoutPercentage: '80',
      tooltips: {
        enabled: false,
        custom: (tooltip: ChartTooltipModel) => {
          const parentElement: HTMLElement = tooltipCanvas.parentElement as HTMLElement;
          let tooltipEl: HTMLElement = parentElement.querySelector('.tooltip') as HTMLElement;
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('tooltip');
            tooltipEl.innerHTML = '<div></div>';
            if (tooltipCanvas) {
              const parent: HTMLElement = tooltipCanvas.parentNode as HTMLElement;
              if (parent) {
                parent.appendChild(tooltipEl);
                parent.style.position = 'relative';
              }
            }
          }

          function getBody(bodyItem: { lines: any; }) {
            return bodyItem.lines;
          }

          if (tooltip.body && tooltipEl) {
            const lines = tooltip.body.map(getBody);
            tooltipEl.style.padding = '5px';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.textAlign = 'center';
            tooltipEl.innerHTML = '<span>';
            lines.forEach((body, i) => {
              tooltipEl.innerHTML += body;
            });
            tooltipEl.innerHTML += '</span>';
            const height = tooltipEl.clientHeight;
            const width = tooltipEl.clientWidth;
            tooltipEl.style.top = `calc(50% - ${height / 2}px)`;
            tooltipEl.style.left = 'calc(50%)';
          }
        },
      },
    });

    tooltipCanvas.addEventListener('mouseout', (event) => {
      const parentElement: HTMLElement = tooltipCanvas.parentElement as HTMLElement;
      const tooltipEl: HTMLElement | null = parentElement.querySelector('.tooltip');

      if (tooltipEl) {
        tooltipEl.remove();
      }
    });
  }
}
